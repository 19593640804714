import createLucideIcon from '../createLucideIcon';

const CalendarMinus = createLucideIcon('CalendarMinus', [
  [
    'path',
    {
      d: 'M21 13V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8',
      key: '3spt84',
    },
  ],
  ['line', { x1: '16', x2: '16', y1: '2', y2: '6', key: 'm3sa8f' }],
  ['line', { x1: '8', x2: '8', y1: '2', y2: '6', key: '18kwsl' }],
  ['line', { x1: '3', x2: '21', y1: '10', y2: '10', key: 'xt86sb' }],
  ['line', { x1: '16', x2: '22', y1: '19', y2: '19', key: '1g9955' }],
]);

export default CalendarMinus;
