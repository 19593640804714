import createLucideIcon from '../createLucideIcon';

const LifeBuoy = createLucideIcon('LifeBuoy', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['path', { d: 'm4.93 4.93 4.24 4.24', key: '1ymg45' }],
  ['path', { d: 'm14.83 9.17 4.24-4.24', key: '1cb5xl' }],
  ['path', { d: 'm14.83 14.83 4.24 4.24', key: 'q42g0n' }],
  ['path', { d: 'm9.17 14.83-4.24 4.24', key: 'bqpfvv' }],
  ['circle', { cx: '12', cy: '12', r: '4', key: '4exip2' }],
]);

export default LifeBuoy;
