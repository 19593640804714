import createLucideIcon from '../createLucideIcon';

const Square = createLucideIcon('Square', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
]);

export default Square;
