import createLucideIcon from '../createLucideIcon';

const MessagesSquare = createLucideIcon('MessagesSquare', [
  [
    'path',
    {
      d: 'M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z',
      key: '16vlm8',
    },
  ],
  [
    'path',
    { d: 'M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1', key: '1cx29u' },
  ],
]);

export default MessagesSquare;
