import createLucideIcon from '../createLucideIcon';

const CalendarDays = createLucideIcon('CalendarDays', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '4',
      rx: '2',
      ry: '2',
      key: 'eu3xkr',
    },
  ],
  ['line', { x1: '16', x2: '16', y1: '2', y2: '6', key: 'm3sa8f' }],
  ['line', { x1: '8', x2: '8', y1: '2', y2: '6', key: '18kwsl' }],
  ['line', { x1: '3', x2: '21', y1: '10', y2: '10', key: 'xt86sb' }],
  ['path', { d: 'M8 14h.01', key: '6423bh' }],
  ['path', { d: 'M12 14h.01', key: '1etili' }],
  ['path', { d: 'M16 14h.01', key: '1gbofw' }],
  ['path', { d: 'M8 18h.01', key: 'lrp35t' }],
  ['path', { d: 'M12 18h.01', key: 'mhygvu' }],
  ['path', { d: 'M16 18h.01', key: 'kzsmim' }],
]);

export default CalendarDays;
