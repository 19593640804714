import createLucideIcon from '../createLucideIcon';

const Files = createLucideIcon('Files', [
  [
    'path',
    {
      d: 'M15.5 2H8.6c-.4 0-.8.2-1.1.5-.3.3-.5.7-.5 1.1v12.8c0 .4.2.8.5 1.1.3.3.7.5 1.1.5h9.8c.4 0 .8-.2 1.1-.5.3-.3.5-.7.5-1.1V6.5L15.5 2z',
      key: 'cennsq',
    },
  ],
  [
    'path',
    { d: 'M3 7.6v12.8c0 .4.2.8.5 1.1.3.3.7.5 1.1.5h9.8', key: 'ms809a' },
  ],
  ['path', { d: 'M15 2v5h5', key: 'qq6kwv' }],
]);

export default Files;
