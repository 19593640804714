import createLucideIcon from '../createLucideIcon';

const CalendarCheck = createLucideIcon('CalendarCheck', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '4',
      rx: '2',
      ry: '2',
      key: 'eu3xkr',
    },
  ],
  ['line', { x1: '16', x2: '16', y1: '2', y2: '6', key: 'm3sa8f' }],
  ['line', { x1: '8', x2: '8', y1: '2', y2: '6', key: '18kwsl' }],
  ['line', { x1: '3', x2: '21', y1: '10', y2: '10', key: 'xt86sb' }],
  ['path', { d: 'm9 16 2 2 4-4', key: '19s6y9' }],
]);

export default CalendarCheck;
