import createLucideIcon from '../createLucideIcon';

const ArrowLeftRight = createLucideIcon('ArrowLeftRight', [
  ['path', { d: 'M8 3 4 7l4 4', key: '9rb6wj' }],
  ['path', { d: 'M4 7h16', key: '6tx8e3' }],
  ['path', { d: 'm16 21 4-4-4-4', key: 'siv7j2' }],
  ['path', { d: 'M20 17H4', key: 'h6l3hr' }],
]);

export default ArrowLeftRight;
