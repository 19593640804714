import createLucideIcon from '../createLucideIcon';

const FilePlus = createLucideIcon('FilePlus', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['line', { x1: '12', x2: '12', y1: '18', y2: '12', key: '1tsf04' }],
  ['line', { x1: '9', x2: '15', y1: '15', y2: '15', key: '110plj' }],
]);

export default FilePlus;
