import createLucideIcon from '../createLucideIcon';

const PanelLeftClose = createLucideIcon('PanelLeftClose', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
  ['path', { d: 'M9 3v18', key: 'fh3hqa' }],
  ['path', { d: 'm16 15-3-3 3-3', key: '14y99z' }],
]);

export default PanelLeftClose;
